// todo: add option for 'not sure' use: Investment holding company (523910)
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../contexts/useAuth";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import _, { set } from "lodash";
import { Clear, ClearAll, Close, ExpandMore, Search } from "@mui/icons-material";
import supabase from "../config/supabaseClient";
import { DataGrid } from "@mui/x-data-grid";
import { replaceSpacesWithAnd, replaceSpacesWithOr } from "../utilities/tools";
import { ClearIcon } from "@mui/x-date-pickers";

// Yup Validation Schema
const schemaEntity = Yup.object().shape({
  name: Yup.string().required("Required"),
  file_number: Yup.string(),
  status: Yup.string(),
  is_numbered: Yup.boolean(),
  legal_element_ending: Yup.string(),
  naics_code: Yup.string(),
  naics_description: Yup.string(),
  english_name: Yup.string(),
  eng_nuans_ref_number: Yup.string(),
  eng_nuans_report_date: Yup.date(),
  min_num_directors: Yup.number(),
  max_num_directors: Yup.number(),
  req_inc_date: Yup.date(),
  official_business_email: Yup.string().email("Invalid email address"),
  is_req_nuans_report: Yup.boolean(),
  last_step: Yup.number(),
  ra_is_standard_address: Yup.boolean().required("Required"),
  ra_lot_address: Yup.string(),
  ra_street_address: Yup.string().required("Required"),
  ra_street_name: Yup.string().required("Required"),
  ra_unit: Yup.string().required("Required"),
  ra_city: Yup.string().required("Required"),
  ra_country: Yup.string().required("Required"),
  ra_jurisdiction: Yup.string().required("Required"),
  ra_postal_code: Yup.string().required("Required"),
});
const schemaEntity_isLot = Yup.object().shape({
  name: Yup.string().required("Required"),
  file_number: Yup.string(),
  status: Yup.string(),
  is_numbered: Yup.boolean(),
  legal_element_ending: Yup.string(),
  naics_code: Yup.string(),
  naics_description: Yup.string(),
  english_name: Yup.string(),
  eng_nuans_ref_number: Yup.string(),
  eng_nuans_report_date: Yup.date(),
  min_num_directors: Yup.number(),
  max_num_directors: Yup.number(),
  req_inc_date: Yup.date(),
  official_business_email: Yup.string().email("Invalid email address"),
  is_req_nuans_report: Yup.boolean(),
  last_step: Yup.number(),
  ra_is_standard_address: Yup.boolean().required("Required"),
  ra_lot_address: Yup.string(),
  ra_street_address: Yup.string(),
  ra_street_name: Yup.string(),
  ra_unit: Yup.string().required("Required"),
  ra_city: Yup.string().required("Required"),
  ra_country: Yup.string().required("Required"),
  ra_jurisdiction: Yup.string().required("Required"),
  ra_postal_code: Yup.string().required("Required"),
});

const StyledModal = styled(Modal)({
  position: "absolute",
  top: 0,
  // transform: "translate(-50%, 0)",
  display: "flex",
  width: "100%",
  maxHeight: "fit-content",

  // alignSelf: "top",
  // alignItems: "top",
  // maxWidth: "400px",
  justifyContent: "center",
});

// =========================================================
// Update Entity w/ activeStep
// =========================================================
async function updateEntity(eid, entityData, step) {
  entityData.last_step = step;
  console.log("from update function eid: ", eid);
  console.log("from update function updated step: ", entityData.last_step);
  console.log("from update function entityData: ", entityData);

  const { data, error } = await supabase.from("entity").update(entityData).eq("id", eid).select().single();

  if (error) {
    console.log("error", error);
  }

  if (data) {
    console.log("updateEntity Function Data result: ", data);
    return data;
  }
}

export default function Step2({ activeStep, setActiveStep, handleNext, handleBack }) {
  let { eid } = useParams();
  const { user } = useAuth();
  // [data] = useSelectDataByEntity("entity", eid);

  let nextStep = 1;

  const [openNaicsModal, setOpenNaicsModal] = useState(false);
  const [inputNaicsValue, setInputNaicsValue] = useState("");
  const [resultsNaincs, setResultsNaics] = useState([]);
  const [hasClickedSearch, setHasClickedSearch] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "default alert",
    severity: "warning",
  });
  const naicsSearchInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      legal_element_ending: "Ltd",
      ra_jurisdiction: "ON",
      ra_street_address: "",
      ra_street_name: "",
      ra_is_standard_address: true,
      ra_city: "",
      ra_postal_code: "",
      inc_is_sole_shareholder: true,
    },

    validationSchema: schemaEntity,
    // onSubmit: async (values) => {
    //   console.log("values", values);
    //   const { error } = await logIn(values);
    //   if (error) {
    //     alert(error.message);
    //   }
    //   // handleSignIn(values);
    // },
  });

  const handleClickNext = async (event) => {
    // event.preventDefault();

    // Validation
    if (formik.values.naics_code === "" || formik.values.naics_code === null) {
      setAlertState({ ...alertState, open: true, message: "Please select a NAICS code" });
      return;
    } else {
      nextStep = 2;
    }

    // Update Entity data to supabase
    updateEntity(eid, formik.values, nextStep).then((data) => {
      console.log("Step1 returned data", data);
      // setEntityData(data);
      formik.setValues(data);
      setActiveStep(data.last_step);
    });
  };

  const handleClose = () => {
    setAlertState({ ...alertState, open: false });
  };

  const handleClickBack = () => {
    handleBack();
  };

  // Naics results data grid config
  const columnsNaics = [
    { field: "code", headerName: "Code", width: 80 },

    { field: "element_description", headerName: "Description", width: 500 },
  ];
  // const columnsNaics = [
  //   { field: "code", headerName: "Code", width: 80 },
  //   { field: "class_title", headerName: "Title", width: 250 },
  //   { field: "element_description", headerName: "Description", width: 500 },
  // ];

  // handle handleClickedNaicsSearch
  const handleClickedNaicsSearch = async () => {
    // event.preventDefault();
    setHasClickedSearch(true);

    const naicsSearchQuery = replaceSpacesWithAnd(inputNaicsValue);
    // run supabase query
    const { data, error } = await supabase.from("naics_codes").select().textSearch("fts", `${naicsSearchQuery}`).select();
    // .then((data) => {
    //   setResultsNaics(data.data);
    //   console.log("data", data.data);
    // });

    if (error) {
      console.log("error", error);
    }

    console.log("data", data.length);

    setResultsNaics(data);
  };

  // handle handleClickedNaicsSearch
  const handleClickedNaicsSelect = (param, event) => {
    event.preventDefault();
    console.log("param", param.row);
    formik.setFieldValue("naics_code", param.row.code);
    formik.setFieldValue("naics_description", param.row.element_description.trim());

    // Reset the modal states
    setInputNaicsValue("");
    setResultsNaics([]);
    setOpenNaicsModal(false);
    setHasClickedSearch(false);
  };

  useEffect(() => {
    // Get Entity Data
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log(error);
        return;
      }

      if (data.length > 0) {
        console.log("getEntityData data", data);
        formik.setValues(data[0]);
        // setActiveStep(data.last_step);
      }
    };
    getEntityData();
  }, [eid]);

  return (
    <>
      <Stack display={"flex"} alignItems={"center"} direction="column" spacing={2} mb={3} p={0}>
        <Typography variant="subtitle1" display={formik.values.naics_description ? "none" : "block"}>
          Click SEARCH to look up the NAICS Code for your company&apos;s primary activity
        </Typography>
        <Typography marginLeft={0} display={formik.values.naics_description ? "block" : "none"}>
          The primary activity of my company is:{" "}
        </Typography>
        <Typography display={formik.values.naics_code ? "block" : "none"} sx={{ fontWeight: 700 }}>
          {_.upperFirst(formik.values.naics_description)} ({formik.values.naics_code})
        </Typography>
      </Stack>

      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Button
          variant="outlined"
          onClick={() => {
            setInputNaicsValue("");
            setOpenNaicsModal(true);
          }}
        >
          {formik.values.naics_code ? "Search Again" : "Search"}
        </Button>
      </Box>

      <Stack direction="row" spacing={2} justifyContent={"space-between"} mt={3}>
        <Button variant="contained" color="primary" onClick={handleClickBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickNext}>
          Next
        </Button>
      </Stack>

      {/* NAICS Lookup Modal       */}
      <StyledModal
        open={openNaicsModal}

        // onClose={handleCloseModelCreateEntity}
      >
        <Box bgcolor={"white"} minWidth={{ xs: 300, sm: 500, md: 700, lg: 900 }} maxWidth={700} padding={3} mt={10}>
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Typography variant="h5" textAlign={"center"}>
              NAICS Code Lookup
            </Typography>
            <IconButton
              width="30px"
              height="30px"
              onClick={() => {
                setOpenNaicsModal(false);
                setResultsNaics([]);
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
          <Typography fontSize={{ xs: "12px", md: "16px" }} marginBottom={3}>
            Provide some keywords that describe your business and we&apos;ll suggest some NAICS codes for you to choose from.
          </Typography>
          <Stack spacing={{ xs: 1, sm: 2 }} justifyContent={"space-between"}>
            <Stack direction={"row"} gap={3}>
              <TextField
                sx={{ width: "90%" }}
                size="small"
                inputRef={naicsSearchInputRef}
                name="naics_search_input"
                value={inputNaicsValue ?? ""}
                // helperText="Enter keywords that describe your business"
                label="What does your business do?"
                autoFocus
                onChange={(e) => setInputNaicsValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClickedNaicsSearch();
                  }
                }}
              />
              <Stack direction={"row"} justifyContent={"end"}>
                {(inputNaicsValue && resultsNaincs.length > 0 && hasClickedSearch) ||
                (inputNaicsValue && resultsNaincs.length === 0 && hasClickedSearch) ? (
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setInputNaicsValue("");
                      setResultsNaics([]);
                      setHasClickedSearch(false);
                      naicsSearchInputRef.current.focus();
                    }}
                  >
                    Clear
                  </Button>
                ) : (
                  <Button type="submit" variant="contained" size="small" color="primary" onClick={handleClickedNaicsSearch}>
                    <Search />
                    {/* <Typography>Search</Typography> */}
                  </Button>
                )}
              </Stack>
              {/* <Box>
                <IconButton
                  onClick={() => {
                    setInputNaicsValue("");
                    setResultsNaics([]);
                    naicsSearchInputRef.current.focus();
                  }}
                >
                  <Clear />
                </IconButton>
              </Box> */}
            </Stack>
          </Stack>
          {resultsNaincs.length > 0 && (
            <div style={{ height: 400, marginTop: 30 }}>
              <DataGrid
                rows={resultsNaincs}
                columns={columnsNaics}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={handleClickedNaicsSelect}
                sx={{ fontSize: 12 }}
              />
            </div>
          )}
          {resultsNaincs.length === 0 && hasClickedSearch && inputNaicsValue && (
            <Stack justifyContent={"start"} p={1} mt={2} spacing={2} bgcolor={"#f5f5f5"} borderRadius={2}>
              <Typography fontWeight={"bold"}>No results found</Typography>
              <Typography>Please try again with different keywords.</Typography>
              <Box border={"1px solid lightgray"} borderRadius={1} p={2} bgcolor={"white"}>
                <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                  <Typography>If you are not sure what to choose, you can select a generic NAICS code:</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenNaicsModal(false);
                      setHasClickedSearch(false);

                      formik.setFieldValue("naics_code", "551114");
                      formik.setFieldValue("naics_description", "Holding companies (managing)");
                    }}
                  >
                    Select Generic
                  </Button>
                </Stack>
              </Box>
            </Stack>
          )}
        </Box>
      </StyledModal>
      <Snackbar
        anchorOrigin={{ vertical: alertState.vertical, horizontal: alertState.horizontal }}
        open={alertState.open}
        onClose={handleClose}
        message={alertState.message}
        severity={alertState.severity}
        key={alertState.vertical + alertState.horizontal}
      />
      {/* <Box marginTop={"50px"}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Debug</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(formik.values, null, 2)}</pre>
            <pre>{JSON.stringify(alertState, null, 2)} </pre>
          </AccordionDetails>
        </Accordion>
      </Box> */}
    </>
  );
}

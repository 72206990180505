import styled from "@emotion/styled";
import {Home, Notifications} from "@mui/icons-material";
import {AppBar, Avatar, Badge, Box, IconButton, Menu, MenuItem, Stack, Toolbar} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import {useState} from "react";
// import { useAuth } from "../contexts/useAuth";;
import {useAuth} from "../contexts/useAuth";
import {useNavigate} from "react-router-dom";
import supabase from "../config/supabaseClient";
import {Toast, Inbox} from "@trycourier/react-inbox";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({theme}) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  // [theme.breakpoints.up("sm")]: {
  //   display: "none",
  // },
}));

export default function NavBar({handleReturnToDashboard}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const {user, signOut} = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  function handleClick(event) {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  async function handleSignOut() {
    const {error} = await supabase.auth.signOut();
    if (error) {
      console.log("error", error);
    }
    navigate("/login");
  }

  return (
    <AppBar position="sticky" sx={{bgcolor: "#eaeaea", boxShadow: "none", width: "100%"}}>
      <StyledToolbar sx={{justifyContent: "space-between"}}>
        {/* <IconButton color="primary" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
          <MenuIcon />
        </IconButton> */}
        <IconButton
          color="primary"
          aria-label="home button"
          edge="start"
          onClick={() => (navigate("/dashboard"), handleReturnToDashboard())}
          // sx={{ mr: 2, display: { xs: "none", sm: "block", width: "50px", height: "50px" } }}
          sx={{mr: 2, display: {width: "50px", height: "50px"}}}
        >
          <Home />
        </IconButton>
        <Stack direction="row" spacing={2} alignItems="center">
          {/* <img src="/skylaunch_logo_blue.svg" alt="Sky Launch Logo" width="200px" height="80px" onClick={() => navigate("/dashboard")} /> */}
          {/* <Button variant="contained" color="primary" onClick={handleSignOut}>
            Logout
            </Button> */}
          <div style={{zIndex: 1000}}>
            <Inbox />
          </div>

          <UserBox>
            <Avatar id="avatar" onClick={handleClick}>
              {/* {user && user?.user_metadata?.name_first[0] + user?.user_metadata?.name_last[0]} */}
            </Avatar>
          </UserBox>
        </Stack>
      </StyledToolbar>
      {/* positioned menu */}

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{mt: 5}}
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        {/* <MenuItem onClick={() => navigate("/billing")}>Billing</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
}

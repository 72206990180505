import { Box, Stack, Typography } from "@mui/material";

import PropTypes from "prop-types";
import { Children } from "react";

const StepHeader = ({ children, title, subtitle, img_src, helpcomponent }) => {
  return (
    <>
      {/* Step header */}
      <Stack
        // direction={{ xs: "column", md: "row" }}
        direction="row"
        spacing={{ xs: 0, md: 3 }}
        justifyContent={{ xs: "center", md: "left" }}
        bgcolor={"card.lightblue"}
        sx={{ mb: 2, py: 1, px: 2, borderRadius: 1 }}
      >
        <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center", alignItems: "center" }}>
          <img src={img_src} alt="location" style={{ width: "150px", height: "150px" }} />
        </Box>

        <Stack direction="column" spacing={2} margin={3} p={1}>
          <Typography fontSize={{ xs: "18px", md: "24px" }} fontWeight={600}>
            {title}
          </Typography>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography fontSize={{ xs: "12px", md: "16px" }}>{subtitle}</Typography>
            {children}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  img_src: PropTypes.string.isRequired,
  helpcomponent: PropTypes.element,
  children: PropTypes.element,
};

export default StepHeader;

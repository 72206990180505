import React, { useEffect, useState } from "react";
import { Signature } from "@progress/kendo-react-inputs";
import { Button, Stack } from "@mui/material";
import toast from "react-hot-toast";

// ! image state (string) must be passed to this component

export default function DrawnSignature({ setSignatureImage, signatureImage }) {
  const [signature, setSignature] = useState(null); //not used
  const [signaturePadWidth, setSignaturePadWidth] = useState(700);

  const handleSignatureChange = (e) => {
    console.log(typeof e.value);
    setSignature(e.value);
  };

  const handleSaveSignature = () => {
    console.log("signatureImage", signatureImage);
    setSignatureImage(signature);
  };

  const handleClearSignature = () => {
    setSignature(null);
    setSignatureImage("");
    toast.success("Signature cleared");
  };

  useEffect(() => {
    const handleResize = () => {
      // Set width based on the window width, you can adjust the logic as needed
      if (window.innerWidth < 768) {
        setSignaturePadWidth(300); // Smaller width for smaller screens
      } else {
        setSignaturePadWidth(700); // Larger width for larger screens
      }
    };

    // Call the resize function initially and add the event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Stack direction="column" spacing={2} alignItems={"center"}>
      <Stack direction="column" spacing={2} justifyContent={"center"} alignItems={"center"}>
        {/* <Signature value={signature} onChange={handleSignatureChange} /> */}
        <Signature value={signature} onChange={handleSignatureChange} smooth={true} exportScale={1} width={signaturePadWidth} height={200} hideLine />
      </Stack>
      {/* <img src={signatureImage} alt="signature" /> */}
      <Stack direction={"row"} gap={3} justifyContent={"space-between"} maxWidth={"700px"} width={"100%"}>
        <Button variant="outlined" onClick={handleClearSignature}>
          Clear
        </Button>
        <Button variant="contained" onClick={handleSaveSignature}>
          Use Signature
        </Button>
      </Stack>
    </Stack>
  );
}

import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import * as htmlToImage from "html-to-image";
import toast from "react-hot-toast";

export default function TypedSignature({ setSignatureImage, signatureImage, viewMode }) {
  // states
  const [typedSignature, setTypedSignature] = useState("");

  const inputRef = useRef();

  function handleTypedSignatureChange(e) {
    setTypedSignature(e.target.value);
  }

  async function handleSaveSignature() {
    const element = document.getElementById("typed-signature");
    if (!element) return;

    const base64image = await htmlToImage.toPng(element);
    setSignatureImage(base64image);
  }

  const handleClearSignature = () => {
    setSignatureImage("");
    setTypedSignature("");
    toast.success("Signature cleared");
  };

  useEffect(() => {
    if (viewMode === "typed") {
      inputRef.current.focus();
    }
  }, [viewMode]);

  return (
    <Stack direction="column" spacing={2} py={2}>
      <TextField focused label="Type your signature" value={typedSignature} variant="outlined" onChange={(e) => handleTypedSignatureChange(e)} />

      {/* {typedSignature && (
        <> */}
      <Typography>Signature Preview</Typography>
      <Box border={1} borderColor={"lightgrey"} borderRadius={1} p={0} height={"80px"}>
        <div id="typed-signature" className="birthstone-regular">
          <div>{typedSignature}</div>
        </div>
      </Box>
      {/* </> */}
      {/* )} */}
      <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
        <Button variant="outlined" onClick={handleClearSignature}>
          Clear
        </Button>
        <Button variant="contained" onClick={handleSaveSignature}>
          Use Signature
        </Button>
      </Stack>
    </Stack>
  );
}

import PropTypes from "prop-types";
import { CheckBox, CheckBoxOutlineBlank, Edit, ExpandMore, SpaceBar, Verified } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import supabase from "../config/supabaseClient";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { get, _ } from "lodash";
import { useAuth } from "../contexts/useAuth";
import AnnualMaintFeatureBlock from "../components/annualMaintFeatureBlock/annualMaintFeatureBlock";
import toast from "react-hot-toast";

const incorporation_features = [
  "Certificate of Incorporation",
  "Articles of Incorporation (standard form)",
  "Company By-Laws (standard form)",
  "Organizing Resolutions (standard form)",
  "Share Register, D&O Register",
  "Beneficial Owner Register",
  "Company Key",
  "Initial Corporate Return Filing",
  "Online Access to Corporate Minute Book (1-year)",
  "$300 Government Fee and $12 Nuans Name Reservation (if required)",
];

const annual_maintenance_features = [
  "Annual Return Preparation and Filing",
  "Annual Shareholder Resolutions (standard form)",
  "Annual Director Resolutions (standard form)",
  "Corporate Register Updates",
  "Newsletter / Corporate Law Updates",
  "Exclusive access to online events/webinars",
  "Share Access to Online Minute Book (Coming Soon)",
];

// Dialog / modal
function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Do you want to become a SkyLaunch Member?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1">
            Skylaunch Membership gives you a host of benefits and helps you stay compliant with your corporate filings.
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography variant="subtitle1" fontWeight={700} py={2}>
            {/* <Chip
              label={"Plus you save $50 on the Incorporation Package."}
              sx={{
                color: "black",
                bgcolor: "chip.green",
                marginBottom: 1,

                fontSize: 16,
              }}
            /> */}
            Plus you save $150 on the Incorporation Package.
          </Typography>
        </DialogContentText>
        <DialogActions>
          <Box display={"flex"} gap={2} justifyContent={"space-between"} width={"100%"}>
            <Button variant="outlined" color="primary" onClick={() => handleClose(false)}>
              No, Thanks
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleClose(true)}>
              Yes, Please!
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.bool.isRequired,
};

const product = 699;
const annualMaintenance = 299;
const subscriptionDiscount = 150;

export default function Step7({ activeStep, setActiveStep, handleNext, handleBack, updateEntityLastStep }) {
  const { eid } = useParams();
  const { user } = useAuth();
  const [entityData, setEntityData] = useState({});

  // modal states
  const [open, setOpen] = useState(false);

  // states
  const [isAnnualMaintenance, setIsAnnualMaintenance] = React.useState(false);
  const [confirmedNoMembership, setConfirmedNoMembership] = React.useState(false);

  // total display states
  const [oneTimeProduct, setOneTimeProduct] = React.useState(product);
  const [subtotal, setSubtotal] = React.useState(oneTimeProduct);

  // memoized values
  const taxTotal = useMemo(() => subtotal * 0.13, [subtotal]);
  const total = useMemo(() => subtotal + taxTotal, [subtotal, taxTotal]);
  const gpAmount = useMemo(() => total.toFixed(2).replace(".", ""), [total]);

  const handleClickNext = async (event) => {
    event.preventDefault();

    // save is_selected_annual_maintenance to entity
    try {
      const { data, error } = await supabase.from("entity").update({ is_selected_annual_maintenance: isAnnualMaintenance }).eq("id", eid);
      if (error) {
        toast.error("Error saving annual maintenance selection");
        return;
      }
    } catch (error) {
      console.log("error", error);
    }

    try {
      const { data: dataClearConsent, error: errorClearConsent } = await supabase
        .from("entity")
        .update({ auth_consent: false, auth_consent_signature: null })
        .eq("id", eid)
        .select("*");
      if (errorClearConsent) {
        console.log("error", errorClearConsent);
        return;
      }

      console.log("dataClearConsent", dataClearConsent);
    } catch (error) {
      console.log("error", error);
      return;
    }

    // Step specific logic here
    // todo: change to 7
    updateEntityLastStep(7);

    handleNext();
  };

  const handleClickBack = () => {
    handleBack();
  };

  // Toggle the selection of annual maintenance
  function handlePressAnnualMaintenance() {
    setIsAnnualMaintenance((current) => !current);
  }

  // Dialog / modal
  function handleOpen() {
    setOpen(true);
  }

  function handleClose(value) {
    setOpen(false);
    console.log("handleclose:value", value);
    setIsAnnualMaintenance(value);
    setConfirmedNoMembership(true);

    //? testing - auto advance to next step if 'false - no thanks selected'
    // if (!value) {
    //   handleNext();
    // }
    //? end of testing
  }

  useEffect(() => {
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("data", data);
        setEntityData(data[0]);

        // set annual maintenance if already selected
        setIsAnnualMaintenance(data[0].is_selected_annual_maintenance);
      }
    };

    getEntityData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eid]);

  useEffect(() => {
    if (isAnnualMaintenance) {
      setOneTimeProduct(product - subscriptionDiscount);
      setSubtotal(oneTimeProduct + annualMaintenance - subscriptionDiscount);
      return;
    } else {
      setOneTimeProduct(product);
      setSubtotal(oneTimeProduct);
    }
  }, [isAnnualMaintenance]);

  return (
    <>
      <Stack direction="column" spacing={2} justifyContent={"space-between"}>
        {/* <Stack direction={"column"} gap={3}> */}
        <Stack direction={{ xs: "column", md: "row" }} gap={3}>
          <Box border={0} borderColor={"lightgrey"} borderRadius={1} p={3}>
            {/* <Stack direction={{ xs: "column", md: "row" }} gap={3} justifyContent={"space-between"} alignItems={{ xs: "center", md: "start" }}> */}
            <Stack direction={"column"} gap={3} justifyContent={"space-between"} alignItems={{ xs: "center", md: "start" }}>
              <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
                <img src="/header/services-pricing.svg" width={250} />
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h6">
                  Incorporation Package:{" "}
                  {entityData.is_numbered ? "Numbered Company" : _.startCase(entityData.english_name + " " + entityData.legal_element_ending)}
                </Typography>
                {/* <Typography variant="subtitle1">Includes:</Typography> */}
                {incorporation_features.map((feature, index) => {
                  return (
                    <Stack key={index} direction="row" spacing={2} display={"flex"} justifyContent={"start"}>
                      <Verified color="primary" />
                      <Typography fontSize={{ xs: "12px", lg: "16px" }}>{feature}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack direction={"column"} gap={1}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                  <CheckBox sx={{ color: "gray" }} />
                  <Typography variant="h3" fontWeight={700} align="right">
                    ${oneTimeProduct}
                  </Typography>
                </Stack>
                <Typography variant="subtitle1" align="right">
                  One-Time + tax
                </Typography>
                <Zoom in={isAnnualMaintenance} timeout={100} style={{ transitionDelay: isAnnualMaintenance ? "200ms" : "0ms" }}>
                  <Chip
                    label={`You saved: -$${subscriptionDiscount}`}
                    sx={{
                      color: "black",
                      bgcolor: "chip.green",
                      marginBottom: 1,
                      visibility: isAnnualMaintenance ? "visible" : "hidden",
                      fontSize: 16,
                      fontWeight: 700,
                      height: 40,
                      px: 1,
                    }}
                  />
                </Zoom>
              </Stack>
            </Stack>
          </Box>
          <Stack direction={"column"} gap={3}>
            <Box
              border={2}
              borderColor={"lightgrey"}
              borderRadius={1}
              p={3}
              sx={{
                ":hover": {
                  borderColor: "grey",
                },
                cursor: "pointer",
                bgcolor: isAnnualMaintenance ? "#3A589605" : "#ffffff",
                borderStyle: isAnnualMaintenance ? "solid" : "dotted",
                borderWidth: isAnnualMaintenance ? "3px" : "3px",
                borderColor: isAnnualMaintenance ? "primary.main" : "lightgrey",
              }}
              transition="all 0.3s"
              onClick={handlePressAnnualMaintenance}
            >
              {/* <Stack direction={"column"} gap={1} mb={2}>
                <Typography variant="h5" fontWeight={700}>
                  Option To Subscribe For Annual Skylaunch Membership:
                </Typography>
              </Stack> */}
              {/* <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "center", md: "start" }} justifyContent={"space-between"} gap={1}> */}
              <Stack direction={"column"} alignItems={{ xs: "center", md: "start" }} justifyContent={"space-between"} gap={1}>
                <Stack direction={"column"} gap={1}>
                  {/* <AnnualMaintFeatureBlock /> */}
                  <Stack direction={"column"} spacing={4} display={"flex"} justifyContent={"start"} alignItems={{ xs: "center", lg: "start" }}>
                    {/* center image */}
                    <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
                      <img src="/header/user-group.svg" height={160} />
                    </Stack>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6" fontWeight={500}>
                        Subscribe for Skylaunch Membership
                      </Typography>
                      <Box>
                        <Chip
                          label="Save $150 on Incorporation"
                          sx={{ color: "black", bgcolor: "chip.green", marginBottom: 1, fontSize: 16, fontWeight: 700, height: 40, px: 1 }}
                        />
                      </Box>
                      {/* <Typography>Annual Membership includes:</Typography> */}
                      {annual_maintenance_features.map((feature, index) => {
                        return (
                          <Stack key={index} direction="row" spacing={2} display={"flex"} justifyContent={"start"}>
                            <Verified color="primary" />
                            <Typography fontSize={{ xs: "12px", lg: "16px" }}>{feature}</Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction={"column"} justifyContent={"center"} gap={1} mt={2}>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                    {isAnnualMaintenance ? <CheckBox sx={{ color: "primary.main" }} /> : <CheckBoxOutlineBlank sx={{ color: "primary.main" }} />}
                    <Typography variant="h3" fontWeight={700}>
                      ${annualMaintenance}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle1" align="right">
                    Per Year + tax
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
          <Button variant="contained" color="primary" onClick={handleClickBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={!confirmedNoMembership && !isAnnualMaintenance ? handleOpen : handleClickNext}>
            Next
          </Button>
        </Stack>
        {/* <pre>{JSON.stringify(entityData, null, 2)}</pre> */}
        {/* <pre>CNM: {JSON.stringify(confirmedNoMembership, null, 2)}</pre> */}
        {/* <pre>AM: {JSON.stringify(isAnnualMaintenance, null, 2)}</pre> */}
      </Stack>
      <SimpleDialog open={open} onClose={handleClose} selectedValue={isAnnualMaintenance} />
    </>
  );
}

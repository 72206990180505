import { Box } from "@mui/material";
import React from "react";

export default function LsoBadge() {
  return (
    <Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <img src={"/lso/lso_badge_svg.svg"} alt="Law Society of Ontario Access to Innovation Badge" width={90} />
    </Box>
  );
}

import React, {useEffect} from "react";
import {useAuth} from "../../contexts/auth";
import {Button, Stack, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import supabase from "../../config/supabaseClient";
import toast from "react-hot-toast";

//? =========================================================================
//? Environment Config
//? =========================================================================
const environmentConfig = {
  development: {
    redirectURL: import.meta.env.VITE_SERVER_DEV_URL,
    messagingServerUrl: "http://localhost:3005",
  },
  production: {
    redirectURL: import.meta.env.VITE_SERVER_PROD_URL,
    messagingServerUrl: "https://skylaunch-api-messaging-production.up.railway.app",
  },
};

const env = import.meta.env.VITE_APP_MODE;
const {redirectURL, messagingServerUrl} = environmentConfig[env];

export default function FirstLogonPopup() {
  const {user, isFirstTimeLogin} = useAuth();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // Create courier profile
  async function createCourierProfile() {
    const url = `${messagingServerUrl}/customer/update-profile`;

    const options = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: `${user.user_metadata.name_first} ${user.user_metadata.name_last}`,
        email: user.email,
        user_id: user.id,
      }),
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        toast.error(`Failed to create courier profile`);
        throw new Error(`Failed to create courier profile: ${response.statusText}`);
      } else {
        toast.success("Profile updated");

        // Update user profile data in supabase and set is_first_time_login to false
        const {data, error} = await supabase.auth.updateUser({data: {is_first_time_login: false}});
        if (error) console.log("error", error);
        if (data) console.log("data", data);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function setFirstTimeLoginToFalse() {
    await createCourierProfile();
  }

  async function resetFirstTimeLogin() {
    const {data, error} = await supabase.auth.updateUser({data: {is_first_time_login: true}});
    if (error) console.log("error", error);
    if (data) console.log("data", data);
  }

  function handleContinue() {
    setFirstTimeLoginToFalse();
    handleClose();
  }

  useEffect(() => {
    if (isFirstTimeLogin) {
      setOpen(true);
    }
  }, [isFirstTimeLogin]);

  return (
    <>
      {/* <Button onClick={resetFirstTimeLogin}>Reset First Time Login</Button> */}
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEscapeKeyDown
      >
        <DialogTitle>Welcome to SkyLaunch</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <img src="/header/map.svg" alt="SkyLaunch Logo" width={100} height={100} />
            <Stack spacing={2}>
              <Typography>We&apos;re excited to have you on board!</Typography>
              <Typography>Please continue with your company setup by selecting your company in the list.</Typography>
            </Stack>
            {/* <Stack>
              <pre>{JSON.stringify(user?.user_metadata, null, 2)}</pre>
            </Stack> */}
          </Stack>

          <DialogActions>
            <Button onClick={handleContinue}>Continue</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

import { Edit, ExpandMore, SpaceBar } from "@mui/icons-material";
import { Box, Button, Checkbox, Chip, IconButton, Skeleton, Stack, Typography, Zoom } from "@mui/material";
import supabase from "../config/supabaseClient";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { get, _ } from "lodash";
import { useAuth } from "../contexts/useAuth";
import toast from "react-hot-toast";
import SignaturePad from "../components/signaturepad/SignaturePad";

const product = 699;
const annualMaintenance = 120;
const subscriptionDiscount = 50;

const environmentConfig = {
  development: {
    stripeServerUrl: import.meta.env.VITE_STRIPE_SERVER_URL_DEV,
  },
  production: {
    stripeServerUrl: import.meta.env.VITE_STRIPE_SERVER_URL_PROD,
  },
};

const env = import.meta.env.VITE_APP_MODE;
const { stripeServerUrl } = environmentConfig[env];

console.log("==============================================================");
console.log("App Mode:", env);
console.log("Stripe Server URL:", stripeServerUrl);
console.log("==============================================================");

// set the stripe server URL based on the environment
// const stripeServerUrl = window.location.hostname === "localhost" ? "http://localhost:4242" : "https://skylaunch-backend-stripe-production.up.railway.app";

function base64ToBlob(base64, mimeType) {
  let byteString;
  if (base64.split(",")[0].indexOf("base64") >= 0) byteString = atob(base64.split(",")[1]);
  else byteString = unescape(base64.split(",")[1]);

  // Separate out the mime component
  mimeType = mimeType || base64.split(",")[0].split(":")[1].split(";")[0];

  // Write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeType });
}

export default function Step8({ activeStep, setActiveStep, handleNext, handleBack, updateEntityLastStep }) {
  const { eid } = useParams();
  const { user } = useAuth();
  const [entityData, setEntityData] = useState({});

  // states
  const [is_consent, setIsConsent] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [stripeCheckoutUrl, setStripeCheckoutUrl] = useState("");
  const [checkoutError, setCheckoutError] = useState(false);

  async function handleCheckout(payload) {
    console.log("payload in handleCheckout", payload);
    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${import.meta.env.VITE_API_KEY}`);

    // console.log("myHeaders", myHeaders);

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
      body: JSON.stringify(payload),
    };

    try {
      setCheckoutError(false);
      const stripe_response = await fetch(`${stripeServerUrl}/stripe/create-checkout-session`, requestOptions);

      const stripe_response_json = await stripe_response.json();

      // console.log("stripe_response_json", stripe_response_json.url);

      setStripeCheckoutUrl(stripe_response_json.url);

      //redirect the user to the stripe checkout page
      if (stripe_response_json.url) {
        window.location.href = stripe_response_json.url;
      }
    } catch (error) {
      console.error("Catch Error:", error);
      toast.error(`An error occurred with checkout. Please try again.`, { duration: 10000 });
      setCheckoutError(true);
    }

    console.log(stripeCheckoutUrl);
  }

  const handleClickNext = async (event) => {
    event.preventDefault();

    // Pass the next step number to the parent component
    // todo: update the entity last step to: 7
    updateEntityLastStep(6);

    // todo: save signature image to entity storage
    // convert the signature image to a blob
    // const blob = new Blob([signatureImage], { type: "image/png" });

    const imageBlob = base64ToBlob(signatureImage, "image/png");

    console.log("type signatureImage", typeof blob);

    //? upload the image to the entity_storage_bucket
    //todo: =========================================================================
    //todo: Change RLS to allow for upload path to be entity_id instead of user.id
    //todo: =========================================================================
    try {
      // const { data, error } = await supabase.storage
      //   .from("entity_storage_bucket")
      //   .upload(user.id + "/" + "consent_signature.png", imageBlob, { upsert: true, contentType: "image/png" });

      const filePath = `${eid}/consent/consent_signature.png`;
      console.log("storage filePath:", filePath);

      const { data, error } = await supabase.storage.from("entity_storage_bucket").upload(filePath, imageBlob, { upsert: true });

      if (error) {
        console.log("error", error);
        toast.error("Error uploading signature");
        alert(error.message);
        return;
      }

      console.log("data", data);

      if (data) {
        toast.success("Building your checkout experience...", { duration: 8000 });
        // update the entity with the auth_consent true
        // save the url to entity record
        const { data: updateData, error: updateError } = await supabase
          .from("entity")
          .update({ auth_consent: true, auth_consent_signature: data.path })
          .eq("id", eid);

        if (updateError) {
          toast.error("Error saving signature to the database");
          return;
        }
        // get the stripe checkout payload
        // ! not required - we will handle this on the stripe server based on entity data
        // ! Simplify logic on the client side
        // const payload = getStripeCheckoutPayload(eid, entityData.is_selected_annual_maintenance);
        // console.log("payload:", payload);
        // todo: ensure that stripe cancel url is step 7

        // update payload customer_email with the user email
        // ! not required - we will handle this on the stripe server based on entity data
        // payload.customer_email = user.email;

        const payload = {
          entity_id: eid,
          email: user.email,
          cancel_path: `${window.location.origin}/setup/entity/${eid}`,
        };

        handleCheckout(payload);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An error occurred");
    }

    // handleNext();
  };

  const handleClickBack = () => {
    handleBack();
  };

  useEffect(() => {
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("data", data);
        setEntityData(data[0]);

        // set annual maintenance if already selected
        // setIsAnnualMaintenance(data[0].is_selected_annual_maintenance);

        //set is_consent
        setIsConsent(data[0].auth_consent);
      }
    };

    getEntityData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eid]);

  // *todo: Add useeffect to always 'clear' the entity record's consent and signature field and delete signature image

  return (
    <>
      <Stack direction="column" spacing={2} justifyContent={"space-between"}>
        {env === "development" && <Chip label="Development mode: checkout is in test mode" sx={{ bgcolor: "chip.orange" }} />}

        <Stack direction="column" spacing={2}>
          <Box border={1} borderColor={"lightgrey"} borderRadius={1} p={3}>
            <Stack direction={"row"} gap={3} justifyContent={"left"} alignItems={{ xs: "start", sm: "center" }}>
              <Checkbox checked={is_consent} onChange={(e) => setIsConsent(e.target.checked)} />
              <Stack direction={"column"} spacing={2}>
                <Typography fontSize={{ xs: "16px", md: "20px" }} fontWeight={600}>
                  Consent, Authorization and Acknowledgement
                </Typography>
                <Typography fontSize={{ xs: "12px", md: "16px" }}>
                  By checking this box, I confirm that I have thoroughly reviewed and verified the accuracy of the details provided for the
                  incorporation of my business.
                </Typography>
                <Typography fontSize={{ xs: "12px", md: "16px" }}>
                  I authorize SkyLaunch to make the necessary filings to complete the incorporation, to file the Initial Return following
                  incorporation and to apply the signature I provide below to the post-incorporation resolutions and the Initial Return offered as
                  part of SkyLaunch’s incorporation package.
                </Typography>
                <Typography fontSize={{ xs: "12px", md: "16px" }}>
                  I acknowledge and understand that SkyLaunch does not accept any responsibility for any errors or omissions in the information
                  submitted. It is my responsibility to ensure that all information is accurate and complete. By proceeding, I agree to these terms
                  and conditions and to SkyLaunch’s Terms of Service which can be found <a href="https://skylaunch.ca/terms-of-service">here</a>.
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Box p={1}>
            <SignaturePad setSignatureImage={setSignatureImage} signatureImage={signatureImage} />
            {/* <Stack direction="column" spacing={2} justifyContent={"center"} alignItems={"center"} p={5}>
              {signatureImage ? <img src={signatureImage} alt="signature" height={100} /> : <Typography>Draw or type your signature</Typography>}
            </Stack> */}
          </Box>
        </Stack>
        <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
          <Button variant="contained" color="primary" onClick={handleClickBack}>
            Back
          </Button>
          <Button
            disabled={!is_consent || !signatureImage}
            variant="contained"
            color="primary"
            onClick={handleClickNext}
            className="glowing-button"
            style={{ display: is_consent && signatureImage ? "block" : "none" }}
          >
            Proceed to Payment
          </Button>
        </Stack>
        {/* <pre>{JSON.stringify(entityData, null, 2)}</pre> */}
      </Stack>
    </>
  );
}

import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ObrNotCompletedMsg({ entity_id, entity_status }) {
  const navigate = useNavigate();
  return (
    <Alert severity="info" icon={false}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
        <img src={"/header/startup.svg"} style={{ maxWidth: "350px", maxHeight: "150px" }} />
        <Box display={"flex"} flexDirection={"column"} spacing={2} p={2}>
          {entity_status === "submitted" ? (
            <Stack>
              <Typography variant="h6">We are currently processing your incorporation</Typography>
              <Typography>Once your incorporation is complete, you will be able to view this section.</Typography>
            </Stack>
          ) : (
            <Stack>
              <Typography variant="h6">Your company is currently being processed</Typography>
              <Typography>Once your company is setup and processed, you will be able to view this section.</Typography>
            </Stack>
          )}
          <Button
            sx={{ my: 0, width: { xs: "100%", sm: "fit-content" }, display: entity_status?.toLowerCase() === "in-progress" ? "block" : "none" }}
            variant="contained"
            size="medium"
            onClick={() => navigate(`/setup/entity/${entity_id}`)}
          >
            Continue
          </Button>
        </Box>
      </Stack>
    </Alert>
  );
}

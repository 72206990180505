import {Box, Button, Card, CardContent, Fade, Grid, Grow, Modal, Skeleton, Stack, TextField, Typography, styled} from "@mui/material";
import supabase from "../config/supabaseClient";
// import { useAuth } from "../contexts/useAuth";;
import {useAuth} from "../contexts/useAuth";
import Entity from "../components/Entity";
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {AnimatePresence, motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import FirstLogonPopup from "../components/firstlogon/FirstLogonPopup";
// import { useOutletContext } from "react-router-dom";

// const schemaCreateNewEntity = Yup.object().shape({
//   entityName: Yup.string().required("Required"),
// });

// const StyledModal = styled(Modal)({
//   display: "flex",
//   top: "-50%",

//   alignSelf: "center",
//   alignItems: "center",
//   // maxWidth: "400px",
//   justifyContent: "center",
// });

export default function Dashboard({entity_data}) {
  // context value from root.jsx via react-router-dom
  // const [selectedEntity, setSelectedEntity] = useOutletContext();
  const {user} = useAuth();
  const [entities, setEntities] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // const [openModelCreateEntity, setOpenModelCreateEntity] = useState(false);

  // const handleOpenModelCreateEntity = () => setOpenModelCreateEntity(true);
  // const handleCloseModelCreateEntity = () => setOpenModelCreateEntity(false);

  const navigate = useNavigate();

  function startEntityCreate() {
    navigate("/setup/start");
  }
  // formik config
  // const formik = useFormik({
  //   initialValues: {
  //     entityName: "",
  //   },
  //   validationSchema: schemaCreateNewEntity,
  //   onSubmit: async (values) => {
  //     console.log("values", values);

  //     const { data, error } = await supabase
  //       .from("entity")
  //       .insert([
  //         {
  //           name: values.entityName,
  //           linked_user: user.id,
  //           status: "In-Progress",
  //         },
  //       ])
  //       .select();
  //     if (error) {
  //       console.log("error", error);
  //     }

  //     if (data) {
  //       console.log("data", data);
  //       handleRefreshEntities(data[0]);
  //       handleCloseModelCreateEntity();
  //       // TODO: navigate to new entity
  //     }
  //   },
  // });

  useEffect(() => {
    // Get entities from Supabase
    const fetchEntities = async () => {
      setIsLoading(true);

      // await user;
      // console.log("user", user);
      // const { data, error } = await supabase.from("entity").select("*").eq("linked_user", user_id).order("name", { ascending: true });
      const {data, error} = await supabase.from("entity").select("*").order("created_at", {ascending: false});

      if (error) console.log("error", error);

      if (data) {
        // console.log("Entities:", data);
        setEntities(data);
        setIsLoading(false);
      }
    };
    fetchEntities();

    // console.log("Dashboard: ", entities);
  }, [user]);

  // const handleRefreshEntities = (newEntity) => {
  //   // console.log("The new entity: ", newEntity);
  //   // * Refresh the entities state list to include the new entity just created
  //   setEntities((previousEntities) => [...previousEntities, newEntity]);
  //   // console.log("entities post add: ", entities);
  // };

  const LoadingSkeleton = () => {
    return (
      // <Stack direction={{ xs: "column", md: "row" }} flexWrap={"wrap"} spacing={2} padding={2} justifyContent={"center"}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "1200px",
          padding: 1,
          margin: "0 auto",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <Box sx={{width: {xs: "95%", md: "300px"}}}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
        <Box sx={{width: {xs: "95%", md: "300px"}}}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
        <Box sx={{width: {xs: "95%", md: "300px"}}}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </Box>
      // </Stack>
    );
  };

  return (
    // <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}>
    <Box>
      {/* <Box flex={2}> */}
      <Stack direction={{xs: "column", md: "row"}} spacing={2} display={"flex"} justifyContent={"space-between"} padding={2}>
        <Typography fontSize={{xs: "16px", md: "20px"}} fontWeight={500} textAlign={"left"} color={"black"}>
          My Companies
        </Typography>

        <Box>
          <Button variant="contained" color="primary" onClick={startEntityCreate}>
            Start a Corporation
          </Button>
        </Box>
      </Stack>

      {/* <TransitionGroup> */}
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <Box sx={{display: "flex", flexWrap: "wrap", width: "100%", maxWidth: "1200px", padding: 1, margin: "0 auto", justifyContent: "flex-start"}}>
          <FirstLogonPopup />
          {entities.map((entity, index) => (
            <Grow in={true} key={index} style={{transformOrigin: "0 0 0"}} timeout={500}>
              <div key={index}>
                <Entity key={entity.id} entity={entity} />
              </div>
            </Grow>
          ))}
        </Box>
        //   </CardContent>
        // </Card>
      )}
    </Box>
  );
}

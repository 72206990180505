import React from "react";
import { Box, Tabs, Tab, Stack } from "@mui/material";
import PropTypes from "prop-types";
import ShareRegisterTable from "./ShareRegisterTable";
import ShareClassesTable from "./ShareClassesTable";
import ShareLedgerTable from "./ShareLedgerTable";

ShareholdersTab.propTypes = {
  entityId: PropTypes.string.isRequired,
};

export default function ShareholdersTab({ entityId }) {
  const [value, setValue] = React.useState(0);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box sx={{ px: { xs: 1, sm: 3 }, py: { xs: 2, sm: 3 } }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Stack sx={{ px: 0 }}>
      <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} variant="scrollable" allowScrollButtonsMobile>
        {/* bgcolor if current tab is selected */}
        <Tab label="Shares" />
        <Tab label="Classes" />
        <Tab label="Ledger" />
        {/* <Tab label="ISC" /> */}
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <ShareRegisterTable entityId={entityId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ShareClassesTable entityId={entityId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ShareLedgerTable entityId={entityId} />
      </CustomTabPanel>
    </Stack>
  );
}
